<template>
  <el-form
    class="auditModel"
    ref="formRef"
    :model="formData"
    :rules="rules"
    size="small"
    label-width="130px"
    label-suffix="："
  >
    <el-form-item label="收件人" prop="requiredForm">
      {{ detailData.name }}
    </el-form-item>
    <el-form-item label="联系电话" prop="requiredForm">
      {{ detailData.phoneNumber }}
    </el-form-item>
    <el-form-item label="收件地址" prop="requiredForm">
      {{ detailData.addressDesc }}
    </el-form-item>
    <!-- <el-form-item label="发票抬头" prop="requiredForm">
      {{ detailData.invoice }}
    </el-form-item> -->
    <el-form-item label="未开票金额">
      {{ detailData.unInvoiceAmount | numberFilter }}
    </el-form-item>
    <el-form-item label="本次申请金额">
      {{ detailData.applyAmount | numberFilter }}
    </el-form-item>
    <el-form-item label="发票内容">
      {{ detailData.contentTypeDesc }}
    </el-form-item>
    <el-form-item label="发票类型">
      {{ detailData.invoiceTypeDesc }}
    </el-form-item>
    <el-form-item label="审核结果" prop="auditStatus">
      <el-radio-group
        v-model="formData.auditStatus"
        @change="$refs.formRef.clearValidate()"
      >
        <el-radio :label="2">通过</el-radio>
        <el-radio :label="3">不通过</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item
      label="发票信息"
      prop="accessFilePath"
      v-if="formData.auditStatus === 2"
    >
      <UploadFile
        type="other"
        :acceptType="pdf"
        class="ml10"
        ref="uploadRef"
        @success="uploadSuccess"
        v-model="formData.accessFilePath"
      ></UploadFile>
      <div style="font-size: 12px">仅支持上传pdf文件格式</div>
      <div class="ml10" v-show="formData.fileName">
        <span>{{ formData.fileName }}</span>
        <i class="el-icon-close cursor ml10" @click="handleRemoveFile()"></i>
      </div>
    </el-form-item>
    <el-form-item
      label="审核意见"
      prop="remark"
      :rules="[
        {
          required: formData.auditStatus == 3 ? true : false,
          message: '请输入审核意见',
        },
      ]"
    >
      <el-input
        type="textarea"
        :autosize="{ minRows: 6, maxRows: 6 }"
        show-word-limit
        maxlength="100"
        v-model="formData.remark"
        suffix-icon="el-icon-date"
        autocomplete="off"
      ></el-input>
    </el-form-item>
    <div class="disfr jc ac">
      <el-button size="small" type="primary" @click="handleSubmitAudit"
        >提交</el-button
      >
    </div>
  </el-form>
</template>
<script>
import UploadFile from "@/components/UploadFile1.vue";
export default {
  components: { UploadFile },
  props: {
    detailData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      formData: {
        auditStatus: "",
        accessFileId: "",
        accessFilePath: "",
        fileName: "",
      },
      rules: {
        auditStatus: [{ required: true, message: "请选择审核结果" }],
        remark: [{ required: true, message: "请输入审核意见" }],
        accessFilePath: [{ required: true, message: "请上传发票信息" }],
      },
      pdf: ["pdf"],
    };
  },
  computed: {
    certificateList() {
      if (!this.detailData.certificate) {
        return [];
      }
      return this.detailData.certificate.split(",");
    },
  },
  methods: {
    uploadSuccess(file) {
      this.$nextTick(() => {
        this.$refs.formRef.clearValidate(["accessFilePath"]);
      });
      this.formData.accessFilePath = file.accessPath;
      this.formData.accessFileId = file.fileId;
      this.formData.fileName = file.fileName;
    },
    handleRemoveFile() {
      this.formData.accessFilePath = "";
      this.formData.accessFileId = "";
      this.formData.fileName = "";
    },
    handleSubmitAudit() {
      this.$refs.formRef.validate((valid) => {
        let uploadValid = true;
        if (this.$refs.uploadRef) {
          // 存在文件上传时，校验文件是否已上传完成
          uploadValid = this.$refs.uploadRef.getValidate();
        }
        if (valid && uploadValid) {
          const data = {
            id: this.detailData.id,
            ...this.formData,
          };
          this.$emit("submit", data);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.auditModel {
  width: 60%;
  margin: 0 auto;
  .el-input,
  .el-textarea {
    display: inline-block;
    width: 300px;
  }
  .formItemRemark {
    font-size: 12px;
    // width:700px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 12px;
    padding-right: 12px;
  }
  .el-date-editor .el-input__prefix {
    left: auto;
    right: 5px;
  }
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 30px;
  }
  .imgsArea {
    display: flex;
    flex-wrap: wrap;

    .image {
      width: 100px;
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
}
</style>
