import request from "@/config/serve.js";

// 查询
export function getDeveloperBillList(data) {
  return request("post", "/developerBill/list", data);
}
//收件人信息
export function getReceiveInfo(params) {
  return request("get", "/developerBill/receiveInfo", params);
}
//开发者充值管理-充值审核
export function auditBilling(data) {
  return request("post", "/developerBill/audit", data);
}
//开票-明细
export function getBillDetails(params) {
  return request("get", "/developerBill/billDetails", params);
}
